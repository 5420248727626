import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Kreditaufnahme = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Die häufigsten Fehler bei der Kreditaufnahme" showCalc={false} />
            <Article>
                <p>
                    Eine Kreditaufnahme ist keine Sache, die man unüberlegt machen sollte. Doch selbst wenn man gut
                    informiert und vorsichtig ist, kann jedem ein Fehler passieren. In diesem Artikel möchten wir
                    deshalb über die häufigsten Fehler bei der Kreditaufnahme aufklären.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Der Kreditbedarf wird falsch eingeschätzt</h2>
                <p>
                    Kein Kredit sollte ohne sorgsame Planung aufgenommen werden und das heißt auch, dass du dir im
                    Vorhinein sehr genau Gedanken darüber machen solltest, wieviel Geld du wirklich brauchst. Denn wenn
                    du dir von der Bank mehr Geld leihst, als du eigentlich brauchst, zahlst du auch mehr Zinsen dafür
                    zurück. Das macht deinen Kredit unnötigerweise teurer. Leihst du dir allerdings zu wenig, musst du
                    später vielleicht erneut einen aufnehmen. Das kann recht teuer werden und ist schwieriger zu
                    bekommen. Sehr häufig wird die eigene finanzielle Situation falsch eingeschätzt. Einen Kredit muss
                    man sich leisten können. Die Summe, die du für deine monatliche Kreditrate zur Verfügung hast, hängt
                    also vor allem von deinem Einkommen ab. Aber auch dieses kann sich in der Zukunft verändern. Die
                    Laufzeit sollte also so kurz wie möglich sein. Man sollte immer einen finanziellen Puffer für solche
                    Krisensituationen haben. Ein Fixzinskredit garantiert die gleichbleibende stabile monatliche Raten,
                    die von den Veränderungen am Finanzmarkt unabhängig sind.
                </p>
                <hr />

                <h2>Monatliche Rate und Laufzeit werden nicht richtig berechnet</h2>
                <p>
                    Eine Kreditaufnahme ist nur sinnvoll, wenn du weißt, dass du die Schulden in einem vernünftigen
                    Zeitraum zurückzahlen kannst. Dafür ihr ein hohes Einkommen sehr nützlich, aber man kann sich dessen
                    nicht für über zehn Jahre sicher sein, weil man nicht weiß was die Zukunft bringt. Deshalb ist eine
                    kurze Laufzeit die bessere Wahl. Wenn du das Geld, das dir monatlich zur Verfügung steht, zu hoch
                    einschätzt, kannst du dir deinen Kredit nicht mehr leisten. Vergiss nicht, dass das Geld für Miete,
                    Versicherungen, Unterhaltszahlungen usw. nicht für die Kreditrate zur Verfügung steht.Gerade bei
                    hohen Kreditsummen lohnt es sich, eine genaue Haushaltsrechnung zu machen. In der kannst du deine
                    regelmäßigen Einnahmen und Ausgaben berechnen und siehst, was du dir wirklich leisten kannst. Zur
                    Berechnung solltest du dir die durchschnittlichen Werte der letzten zwölf Monate ansehen..
                </p>
                <p>
                    Außerdem darf das überbleibende Geld nicht vollständig für deinen Kredit verwendet werden. Es ist
                    wichtig, dass dir immer ein finanzieller Puffer bleibt. Mit unserem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    kannst du eine erste Einschätzung bekommen.
                </p>
                <hr />

                <h2>Die Flexibilität wird unterschätzt</h2>
                <p>
                    Die niedrigsten Zinsen bringen nicht immer den günstigsten Kredit. Du kannst die Kosten während der
                    Rückzahlung immer auch mit kostenlosen{" "}
                    <Link to="/artikel/tilgung/" target="_blank" rel="noreferrer noopener">
                        Sondertilgungen
                    </Link>{" "}
                    senken. Informiere dich vor der Kreditaufnahme darüber, ob deine Bank diese Möglichkeit bietet. Dann
                    kannst du unregelmäßige Einnahmen, wie Steuerrückzahlungen oder Weihnachtsgelder auch für die
                    schnelle Rückzahlung des Kredits nutzen. Dadurch wird sowohl deine Restschuld als auch deine
                    Laufzeit kleiner und du kannst viel Geld sparen. Auch über die Möglichkeit einer Ratenpause solltest
                    du dich vor der Kreditaufnahme informieren. Wenn du einmal mehr finanziellen Spielraum brauchst,
                    erspart sie dir teure Nachfinanzierungen und Dispokredite.
                </p>
                <hr />

                <h2>Kredite vorher nicht vergleichen</h2>
                <p>
                    Der größte Fehler ist wohl, sich zu schnell für ein Kreditangebot zu entscheiden und erst nach der
                    Kreditaufnahme zu bemerken, dass es viel günstiger hätte sein können. Egal ob du gerade in einer
                    finanziellen Notlage steckt oder unter Zeitdruck stehst: Wenn du dich auf das erstbeste Angebot
                    einlässt, wird es meistens ziemlich teuer. In der digitalen Zeit gibt es keinen Grund, auf einen
                    Kreditvergleich zu verzichten. Kreditvergleiche sind mittlerweile eine Sache von Minuten und diese
                    investierten Minuten lohnen sich, denn sie sparen dir einiges an Geld bei deinem Kredit. Hier
                    erfährst du mehr über den{" "}
                    <Link to="/dienstleistungen/kreditvergleich/" target="_blank" rel="noreferrer noopener">
                        Kreditvergleich in Österreich
                    </Link>
                    .
                </p>
                <hr />

                <h2>Nicht genug über Zinsmodelle und Co. informiert sein</h2>
                <p>
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    sind bei der Kreditaufnahme eines der wichtigsten Kriterien. Aktuell sind die Zinsen für Kredite in
                    Österreich allgemein sehr günstig, aber sie befinden sich im Steigen. Durch die aktuelle
                    weltpolitische Lage ist die Situation am Finanzmarkt ziemlich unberechenbar. Trotzdem gibt es einige
                    Dinge, an denen du dich orientieren kannst. Zinsen sind am günstigsten, wenn du sie mit einem
                    Bausparer verbindest. Hier sind weniger als 2 % sind durchaus möglich. Bei einem Hypothekarkredit
                    musst du mit ca. 3,5-4 % rechnen. Die Höhe der Zinsen hängt von der Höhe deines Kredits, der
                    Laufzeit und deiner Bonität ab. Wenn du planst, ein Haus zu bauen, bist du mit dem Fixzins-Modell am
                    besten beraten. Denn ein Hausbau dauert und je länger die Laufzeit, desto mehr Unsicherheiten am
                    Finanzmarkt sind die monatlichen Raten ausgesetzt. Fixe Zinsen sorgen dafür, dass du weißt, was dich
                    erwartet. Für eine Kreditaufnahme, bei der mit kürzerer Laufzeit gerechnet werden kann, eignet sich
                    ein allerdings ein variables Zinsmodell besser.
                </p>
                <hr />

                <h2>Man hat keine vertrauenswürdigen festen Ansprechpartner</h2>
                <p>
                    Du musst da nicht alleine durch. Vielleicht kennst du jemanden, der bereits einen Kredit aufgenommen
                    hat, oder sich damit auskennt. Ansonsten gibt es viele Menschen, die sich die
                    Immobilienfinanzierungsberatung zum Beruf gemacht haben. Such dir einen festen Ansprechpartner, der
                    dich durch den Prozess begleitet. Unsere Berater:innen negmen sich Zeit für dich und stehen dir auch
                    online jederzeit zur Verfügung. Auch deine Hausbank hat Berater:innen, die dir zur Seite stehen
                    können. Gerade bei einer so wichtigen Entscheidung wie der Kreditaufnahme kann es eine große Hilfe
                    sein, sich mit Fragen an jemanden wenden zu können.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"kreditaufnahme"}></BreadcrumbList>
            <ArticleStructuredData page={"kreditaufnahme"} heading={"Die häufigsten Fehler bei der Kreditaufnahme"} />
        </Layout>
    );
};

export default Kreditaufnahme;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.kreditaufnahme", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
